"use client";
import { useTranslations } from "next-intl";
import TestimonialsCarusel from "../testimonials-carusel";

function Testimonials() {
  const t = useTranslations("testimonials");
  return (
    <section className="pt-[100px] pb-[80px] bg-[#F7F7F7]">
      <div className="container flex flex-col lg:flex-row items-center justify-center">
        {/* left side */}
        <div className="lg:flex-1">
          <h2 className="text-[32px] lg:text-[48px] leading-[48px] lg:leading-[72px] font-bold">
            {t("title1")}
            <span className="gradient-text">{t("title2")}</span>
          </h2>
          <p>{t("subtitle")}</p>
        </div>

        {/* right side   */}
        <div className="lg:flex-1  ">
          <TestimonialsCarusel />
        </div>
      </div>
    </section>
  );
}
export default Testimonials;
