"use client";
import { Search } from "lucide-react";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useState } from "react";
import DatePicker from "../DatePicker";
import SelectPicker from "../SelectPicker";
import { Button } from "../ui/button";

function Hero() {
  const [pickupDate, setPickupDate] = useState<Date>(new Date());
  // in 2 days from today
  const [dropoffDate, setDropoffDate] = useState<Date>(
    new Date(new Date().setDate(new Date().getDate() + 2))
  );

  const handlePickupDateChange = (date: Date) => {
    setPickupDate(date);
    // Set dropoff date to 2 days after pickup date
    const newDropoffDate = new Date(date);
    newDropoffDate.setDate(date.getDate() + 2);
    setDropoffDate(newDropoffDate);
  };

  const t = useTranslations("hero");

  return (
    <section className=" min-h-auto lg:min-h-[120vh]  flex flex-col  pt-[150px] lg:pt-[150px] pb-14 bg-[#E9E4DF] bg-[url('/hero-bg.png')] bg-center bg-cover bg-no-repeat rounded-b-[20px]">
      <div className="container flex-1 flex flex-col  justify-between">
        <div className="mx-auto text-center flex flex-col">
          <h1 className="text-4xl  text-center mx-auto lg:text-5xl text-white font-bold leading-[1.2]">
            {t("title1")}
            <br />
            <span className="text-[#FFD700]">{t("title2")}</span>
          </h1>
          <p className="text-[16px] mt-4 lg:text-sm font-normal leading-[32px] text-white mb-4 font-poppins">
            {t("subtitle")}
          </p>
          {/* <div className="flex items-center text-[1rem] font-semibold  justify-center gap-4">
            <Button asChild variant="secondary">
              <Link href="/rent-a-car-bucuresti">
                <ArrowRight className="w-4 h-4" />
                {t("buttons.seeCars")}
              </Link>
            </Button>
            <Button asChild>
              <Link href={`tel:${config.eleiPhoneNumber}`}>
                <Phone className="w-4 h-4" fill="white" />
                {t("buttons.bookNow")}
              </Link>
            </Button>
          </div> */}
        </div>

        {/* whie card */}

        <div className="bg-white self-end w-full lg:w-[83%] mx-auto  mt-6 py-[20px] px-[30px] rounded-[10px]">
          <form>
            <ul className="flex flex-col lg:flex-row items-center justify-between gap-4">
              <li className="flex flex-col pb-5 w-full gap-2 border-b-[2px] lg:border-b-0 lg:border-r-[2px] border-r-gray-300">
                <label htmlFor="pickup" className="font-semibold">
                  {t("pickupDate")}
                </label>
                <DatePicker
                  defaultDate={pickupDate}
                  date={pickupDate}
                  setDate={handlePickupDateChange}
                />
              </li>
              {/* predare */}
              <li className="flex flex-col pb-5 w-full gap-2 border-b-[2px] lg:border-b-0 lg:border-r-[2px] border-r-gray-300">
                <label htmlFor="pickup" className="font-semibold">
                  {t("dropoffDate")}
                </label>
                <DatePicker
                  defaultDate={dropoffDate}
                  date={dropoffDate}
                  setDate={setDropoffDate}
                  minDate={new Date(pickupDate.getTime() + 48 * 60 * 60 * 1000)} // minimum 2 days after pickup
                />
              </li>
              {/* locatie ridicare */}
              <li className="flex flex-col pb-5 w-full gap-2 border-b-[2px] lg:border-b-0 lg:border-r-[2px] border-r-gray-300">
                <label htmlFor="pickup" className="font-semibold">
                  {t("pickupLocation")}
                </label>
                <SelectPicker
                  values={["Sediul elei", "Adresa preferentiala"]}
                />
              </li>

              {/* locatie predare */}
              <li className="flex flex-col pb-5 w-full  gap-2 ">
                <label htmlFor="pickup" className="font-semibold">
                  {t("dropoffLocation")}
                </label>
                <SelectPicker
                  values={["Sediul elei", "Adresa preferentiala"]}
                />
              </li>
            </ul>

            <Button asChild className=" mt-4 w-full">
              <Link
                href={`/rent-a-car-bucuresti?pickupDate=${
                  pickupDate.toISOString().split("T")[0]
                }&dropoffDate=${dropoffDate.toISOString().split("T")[0]}`}
              >
                <Search className="w-4 h-4" />
                {t("search")}
              </Link>
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Hero;
