"use client";
import { formatUrlTextBlog } from "@/lib/utils";
import { blogs } from "@prisma/client";
import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { Button } from "../ui/button";

export const BlogCard = ({
  title,
  excerpt,
  img,
}: Pick<blogs, "excerpt" | "img" | "title">) => {
  const t = useTranslations("blogs");

  return (
    <div className="rounded-[3px] bg-[#F7F7F7] p-[1rem] flex flex-col">
      <div className="w-full h-[200px] rounded-[3px] relative overflow-hidden">
        <Image
          src={img || ""}
          alt={title || ""}
          fill
          className="object-cover"
        />
      </div>
      <h3 className="text-[20px] leading-[36px] font-semibold mt-[1rem]">
        {title}
      </h3>
      <p className="text-[16px] leading-[24px] mt-[1rem] flex-1 ">{excerpt}</p>

      <Button
        asChild
        size="sm"
        variant="yellow-gradient"
        className=" rounded-md "
      >
        <Link href={`/blog/citeste/${formatUrlTextBlog(title || "")}`}>
          {t("button")}
        </Link>
      </Button>
    </div>
  );
};
