"use client";
import { ChevronRightIcon } from "lucide-react";
import { useTranslations } from "next-intl";
import Link from "next/link";
import React from "react";
import { Button } from "../ui/button";

function HomeCarsClient({ children }: { children: React.ReactNode }) {
  const t = useTranslations("homeCars");
  return (
    <section className="mt-[3rem] pb-[120px]">
      <div className="container">
        <div className="flex flex-col mb-[30px]">
          <h2 className="text-[32px] lg:text-[48px] leading-[48px] lg:leading-[72px] font-bold">
            {t("title")}
          </h2>
          <p className="text-[16px] text-[#727272]">{t("subtitle")}</p>
        </div>
        {children}
        <div className="flex justify-center mt-[3rem]">
          <Button asChild variant="yellow-gradient" className="p-7">
            <Link href="/rent-a-car-bucuresti">
              {t("discover")}
              <div className="w-[30px] h-[30px] bg-black rounded-full flex items-center justify-center text-white">
                <ChevronRightIcon className="w-[10px] h-[10px]" />
              </div>
            </Link>
          </Button>
        </div>
      </div>
    </section>
  );
}

export default HomeCarsClient;
