"use client";

import { format } from "date-fns";
import { enUS, ro } from "date-fns/locale";
import { useLocale } from "next-intl";

import { Calendar } from "@/components/ui/calendar";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

function DatePicker({
  defaultDate,
  date,
  setDate,
  minDate,
}: {
  defaultDate: Date;
  date: Date;
  setDate: (date: Date) => void;
  minDate?: Date;
}) {
  const locale = useLocale();
  const dateLocale = locale === "ro" ? ro : enUS;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <span className="text-md cursor-pointer text-gray-400">
          {format(defaultDate, "dd MMMM yyyy", { locale: dateLocale })}
        </span>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(date) => setDate(date || new Date())}
          disabled={(date) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (minDate) {
              return (
                date < today || date < minDate || date < new Date("1900-01-01")
              );
            }
            return date < today || date < new Date("1900-01-01");
          }}
          locale={dateLocale}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

export default DatePicker;
