import React from "react";
import { Card } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Image from "next/image";

const testimonials = [
  {
    id: 1,
    name: "Vlad C",
    description:
      "Am fost plăcut surprins de starea mașinii și de amabilitatea celori de la Elei RC. Voi reveni cu siguranță.",
    image: "/review-elei-rent-a-car-1.webp",
  },
  {
    id: 2,
    name: "Oana Cormanu",
    description:
      "Am inchiriat un R8 pentru o zi si o sa fiu fericita pentru un an intreg! Best day ever! Ty Elei",
    image: "/review-elei-rent-a-car-2.webp",
  },
];

function TestimonialsCarusel() {
  return (
    <Carousel className="w-full flex flex-col-reverse lg:flex-row   p-10 gap-4 ">
      <div className="flex lg:flex-col items-center justify-center gap-2">
        <CarouselPrevious className="w-10 h-10 -rotate-[90deg] lg:rotate-[0deg]" />
        <CarouselNext className="w-10 h-10 -rotate-[90deg] lg:rotate-[0deg]" />
      </div>
      <CarouselContent className=" lg:w-[476px] lg:h-[317px] py-10 ">
        {testimonials.map((testimonial) => (
          <CarouselItem key={testimonial.id} className=" w-full h-full">
            <Card className="w-full h-full p-[30px] relative">
              <div className="absolute z-40 bg-white  top-[-40px] left-[30px] p-2 w-[69px] h-[69px] rounded-full overflow-hidden ">
                <div className="relative w-full h-full rounded-full overflow-hidden ">
                  <Image src={testimonial.image} alt={testimonial.name} fill />
                </div>
              </div>

              <h5 className="text-[24px] mb-[10px] font-semibold">
                {testimonial.name}
              </h5>
              <p className="text-black/60 text-[18px] leading-[32px]">
                {testimonial.description}
              </p>
            </Card>
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
}

export default TestimonialsCarusel;
