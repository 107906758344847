"use client";

import { useTranslations } from "next-intl";

function BlogClient() {
  const t = useTranslations("blogs");
  return (
    <div>
      <h2 className="text-[32px] lg:text-[48px] leading-[48px] lg:leading-[72px] font-bold  mb-[13px] pr-[3%]">
        {t("title1")}

        <span className="gradient-text mx-2">{t("title2")}</span>
      </h2>
      <p>{t("subtitle")}</p>
    </div>
  );
}

export default BlogClient;
