"use client";
import { PhoneIcon } from "lucide-react";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { Button } from "../ui/button";
function WantToRentCar() {
  const t = useTranslations("wantToRentCar");
  return (
    <section className="mt-[130px] mb-[130px]">
      <div className="container flex flex-col items-center">
        <h2 className="text-[32px] lg:text-[48px] leading-[48px] lg:leading-[72px] font-bold text-center  mb-[13px] pr-[3%]">
          {t("title1")}
          <span className="gradient-text">{t("title2")}</span>
          {t("title3")}
        </h2>
        <p className="text-center ">{t("description")}</p>

        <Button asChild variant="yellow-gradient" className="mt-5 p-7">
          <Link href="tel:+40750481850">
            <PhoneIcon className="size-5" fill="black" />
            {t("button")}
          </Link>
        </Button>
      </div>
    </section>
  );
}
export default WantToRentCar;
